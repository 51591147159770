<template>
    <span class="w-100 d-block cursor-pointer" v-if="!edit"
          @click="change" v-tooltip="`اصلاح ${label}`">
        {{$toLocal(item[target]) || '--'}}
    </span>
    <b-overlay rounded :show="loading" v-else>
        <b-form @submit.prevent="submit" @reset.prevent="edit=false" class="d-flex align-items-center">
            <b-form-input dir="ltr" class="py-0 px-50" v-model="data" @input="data = $toLocal(data)"/>
            <b-button v-tooltip="'ثبت'" class="btn-icon rounded-circle ml-50" size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit">
                <feather-icon icon="CheckIcon"/>
            </b-button>
            <b-button v-tooltip="'لغو'" class="btn-icon rounded-circle ml-50" size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-secondary" type="reset">
                <feather-icon icon="XIcon"/>
            </b-button>
        </b-form>
    </b-overlay>
</template>

<script>
    import {BButton, BFormInput, BForm, BOverlay} from "bootstrap-vue";

    export default {
        name: "AccountLevelTableField",
        props: ['item', 'target','label'],
        components: {
            BButton,
            BFormInput,
            BForm,
            BOverlay
        },
        data() {
            return {
                edit: false,
                data: this.$toLocal(this.item[this.target]),
                loading: false
            }
        },
        methods: {
            async submit() {
                this.loading = true
                let data = {}
                data[this.target] = this.$S2N(this.data)
                const res = await this.$axios.post('/users/account-level/' + this.item.id, data).catch(() => {
                })
                this.loading = false
                if (res) {
                    this.item[this.target] = this.$S2N(this.data)
                    const text = 'مقدار ' + this.label + this.$coinLabel[this.item.coin] + ' به مقدار ' + this.item[this.target] + ' تغییر یافت. '
                    this.$error('', text, 'success')
                    this.edit = false
                }
            },
            change() {
                this.data = this.item[this.target]
                this.edit = true
            },
        }
    }
</script>

<style scoped>

</style>